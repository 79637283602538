<template>
    <div>
       <div class="button">
            <el-button type="primary"  @click="downPlant">下载图谱</el-button>
            <el-button type="primary"  @click="exportToExcel">导出Excel</el-button>
       </div>
        <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        style="width: 100%; margin-top: 20px">
        <el-table-column
            prop="uuid"
            label="ID"
            width="180">
        </el-table-column>
        <el-table-column
            prop="note.text"
            label="身份">
        </el-table-column>
        <el-table-column
            prop="note.relevance"
            label="重要性">
        </el-table-column>
        <el-table-column
            prop="question1"
            label="问题一">
        </el-table-column>
        <el-table-column
            prop="question2"
            label="问题二">
        </el-table-column>
        <el-table-column
            prop="question3"
            label="问题三">
        </el-table-column>
        <el-table-column
            prop="question4"
            label="问题四">
        </el-table-column>
        <el-table-column
            prop="question5"
            label="问题五">
        </el-table-column>
        <el-table-column
            prop="line"
            label="关系">
            <template slot-scope="scope">
                {{ line(scope.row.lines) }}
            </template>
        </el-table-column>
        <el-table-column
            prop=""
            label="图谱">
            <template slot-scope="scope">
                <el-image 
                    style="width: 200px"
                    :src="baseUrl+`/api/images/`+scope.row.uuid" 
                    :preview-src-list="[baseUrl+`/api/images/`+scope.row.uuid]">
                </el-image>
            </template>
        </el-table-column>
        </el-table>
        <el-pagination
            style="padding-top: 20px;"
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            @current-change="page"
            :total="total">
        </el-pagination>
    </div>
  </template>
  
  <script>
  import ExcelJS from 'exceljs';
  import {getInfomation,getImage} from '../api/api';
    export default {
      data() {
        return {
            //页面
            pageSize:10,
            total:0,
            //当前第几页
            current:1,
            //图谱数据
            tableData: [],
            base64:"",
            baseUrl:"https://wechat.rmcloud.com.cn",
            loading:""
        }
      },
      computed:{
        line() {
            return function(data) {
                let text = "";
                data.forEach(o=>{
                    text += o.text1+'-'+o.text2+'  【'+o.color+'】;'
                })
                return text;
            };
        }
      },
      mounted(){
        this.get_infomation();
      },
      methods: {
        //下载图谱
        downPlant(){
            const url = this.baseUrl+"/download_zip/"

            const a = document.createElement('a');
            a.href = url;
            console.log(url);
            a.download = '图谱.xlsx';
            a.click();

            window.URL.revokeObjectURL(url);
        },
        //换页
        page(e){
            console.log(e);
        },
        //获取数据
        get_infomation(){
            getInfomation(this.pageSize,this.current).then(res=>{
                if(res.data.code==200){
                    this.total = res.data.total;
                    // console.log(res.data.data);
                    const data = this.handle_infomation(res.data.data);
                    this.tableData = data;
                    console.log(data);
                }
            })
        },
        //数据预处理
        handle_infomation(data){
            var tableData = [];
            // data  = JSON.parse(data);
            data.forEach(element => {
                element.notes.forEach(o=>{
                    o.count = element.notes.length;
                    let list = {
                        uuid:element.uuid,
                        note:o,
                        lines:[],
                    };
                    //提取连线
                    element.lines.forEach(line=>{
                       if(line.key1==o.key){
                            const data ={
                                text1: o.text,
                                text2:element.notes.find(find=>find.key==line.key2).text,
                                color:line.color
                            }
                            list.lines.push(data);
                       }
                    })

                    //去除不要的数据
                    list.note.quesiton=null;
                    //提取问题
                    o.question.forEach(quesiton=>{
                        // console.log(quesiton.index);
                            list['question'+`${quesiton.index}`] = quesiton.answer;
                        })
                    tableData.push(list);
                })
        
            

            });
            return tableData.sort((a,b)=>{
                    if (a.uuid === b.uuid) {
                        return a.note.key - b.note.key;
                    }
                    return a.uuid.localeCompare(b.uuid);
                });
        },
        //合并相同的单元格
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0 || columnIndex === 9) {
                if (row.note.key==0) {
                    return {
                    rowspan: row.note.count,
                    colspan: 1,
                    };
                } else {
                    return {
                    rowspan: 0,
                    colspan: 0
                    };
                }
            }
        },
        //获取表格
        handleClick(row) {
          console.log(row);
        },
        //转base64
        arrayBufferToBase64(buffer) {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return btoa(binary);
        },
        //获取图谱
        async get_image(uuid){
            const data = await getImage(uuid);
            console.log(data);
            return  this.arrayBufferToBase64(data.data);
        },

        async exportToExcel() {
           
            this.loading = this.$loading({
                lock: true,
                text: '数据拉取中！',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await getInfomation(this.total,this.current)
            this.loading.close();
            if(data.data.code!=200){
                return;
            }
            this.loading = this.$loading({
                lock: true,
                text: '数据处理中！',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            data  = this.handle_infomation(data.data.data);
           


            this.populateAndExport(data)
                .then(() => {
                    console.log('Excel exported successfully');
                    this.loading.close();
                })
                .catch(error => {
                    console.error('Error exporting Excel:', error);
                    this.loading.close();
                });
        },

        async populateAndExport(data) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet1');
            worksheet.addRow(["ID", "身份", "重要性", "Question 1", "Question 2", "Question 3", "Question 4", "Question 5","连线","x坐标","y坐标"]);
            worksheet.getColumn("A").width = 15; // 设置第一列的宽度为15
            worksheet.getColumn("B").width = 20; // 设置第二列的宽度为20
            worksheet.getColumn("J").width = 30; // 设置第二列的宽度为20
            await this.populateWorksheet(worksheet, data,workbook);
            console.log("2")

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = '导出数据.xlsx';
            a.click();

            window.URL.revokeObjectURL(url);
            },

        
         // 填充数据到工作表的方法
        async populateWorksheet(worksheet, data,workbook) {
            let prevUUID = null;
            let mergeStartRow = 2;
            let rowsToMerge = 1;

                for (let index = 0; index < data.length; index++) {
                    const item = data[index];

                    if (item.uuid !== prevUUID) {
                        if (rowsToMerge > 1) {
                            const mergeEndRow = mergeStartRow + rowsToMerge - 1;
                            await worksheet.mergeCells(`A${mergeStartRow}:A${mergeEndRow}`);
                           

                            //const imgPath = 'http://timetable.rmcloud.com.cn/brjAkxBPYocO240ce6fa00f65813397ec9053e1839d8.png'; // 图片路径
                            // const imgId = workbook.addImage({
                            //     base64: await this.get_image(item.uuid),
                            //    extension: 'png',
                            // });
                            // //worksheet.addImage(imgId, `J${mergeStartRow}`);
                            //                // 获取合并的单元格的宽度和高度
                               
                            //         worksheet.addImage(imgId, {
                            //             tl: { col: 9, row: mergeStartRow - 1 }, // 在第9列
                            //             ext: { width: 65,height:30 } // 图片的大小
                            //       });


               


                            mergeStartRow = mergeEndRow + 1;
                        }
                        rowsToMerge = 1;
                    } else {
                        rowsToMerge++;
                    }


                    let text = "";
                    item.lines.forEach(o=>{
                        text += o.text1+'-'+o.text2+'  【'+o.color+'】;'
                    })

                    await worksheet.addRow([
                        item.uuid,
                        item.note.text,
                        item.note.relevance,
                        item.question1,
                        item.question2,
                        item.question3,
                        item.question4,
                        item.question5,
                        text,
                        item.note.x.toString().substring(0, item.note.x.toString().indexOf('.') + 3),
                        item.note.y.toString().substring(0, item.note.y.toString().indexOf('.') + 3)
                    ]);

                    
                    prevUUID = item.uuid;
                }

                if (rowsToMerge > 1) {
                    const mergeEndRow = mergeStartRow + rowsToMerge - 1;
                    await worksheet.mergeCells(`A${mergeStartRow}:A${mergeEndRow}`);
                  
                    // const imgId = workbook.addImage({
                    //            base64: await this.get_image(prevUUID),
                    //            extension: 'png',
                    //         });
                    //         // 获取合并的单元格的宽度和高度
  
                    //         //worksheet.addImage(imgId, `J${mergeStartRow}`);
                    // worksheet.addImage(imgId, {
                    //             tl: { col: 9, row: mergeStartRow - 1 }, // 在第9列
                    //             ext: { width: 65,height:30 } // 图片的大小
                    //         });

                }

            }


        }

    }
  </script>
  
<style lang="less">
    .button{
        text-align: right;
        padding-right: 30px;
    }
</style>