<template>
  <div class="container">
    <div class="head">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">数据表</el-menu-item>
        <!-- <el-menu-item index="2">功能二</el-menu-item>
        <el-menu-item index="3" >功能三</el-menu-item> -->
      </el-menu>
    </div>
    <div class="body">
      <router-view/>
    </div>
 </div>
</template>

<script>
export default {
  name: 'HomeView',
  props:{

  },
  components:{

  },
  data() { 
    return {
      activeIndex: '1',
    }
  },
  created() {

  },
  methods:{
    handleSelect(key,keyPath){

    }
  }
 }
</script>

<style lang='less' scoped>
.container{
  
}
.head{
  position: flex;
  top: 0px;
}
.body{
  margin: 10px;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}
</style>