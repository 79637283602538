import http from "@/utils/http";
import request from "@/utils/request"

/**
 * 获取某页数据
 * @param {*} pageSize 
 * @param {*} current 
 * @returns 
 */
export function getInfomation(pageSize,current){
    const params = {
        PageSize:pageSize,
        PageCurrent:current
    }
    return http.get("/GetInfo/",params);
}

export function getImage(uuid){
    return request({
        url: "/api/images/"+uuid,
        method: "get",
        responseType:'arraybuffer',
      });
}

